import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StartService {
   errorEvent:any;
  constructor(public http:HttpClient,

    @Inject(PLATFORM_ID) private platformId: Object
    ) {}
  async load() {//
    return new Promise(async (resolve, reject) => {
      const version = localStorage.getItem("VERSION");
      const html = await fetch("/?_timestame=" + Date.now()).then((res) => res.text());
      const regex = /src\s*=\s*["']([^"']*\.js)["']/g;
      let matches;
      const scripts = [];
      while ((matches = regex.exec(html)) !== null) { scripts.push(matches[1]) }
      const newVersion = scripts.sort().toString();

      if (version && version !== newVersion) {//刷新
        localStorage.setItem("VERSION", newVersion);
       // location.href = "/";
      } else {
        localStorage.setItem("VERSION", newVersion);
      }
      resolve(true);
    })
  }
}

